import { salesforceApiAuthHeaders } from './authHeaders'

const fetchTestSmsTelephoneNumber = async () => {
  const url = `${process.env.NEXT_PUBLIC_SALESFORCE_API_BASEURL}/metadata/testSmsTelephoneNumber`
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: salesforceApiAuthHeaders,
    })
    const data = await response.json()
    const testNumber = data?.records[0].Test_SMS_Phone_Number__c
      ? data.records[0].Test_SMS_Phone_Number__c
      : ''
    return testNumber
  } catch (err) {
    return null
  }
}

export default fetchTestSmsTelephoneNumber
