import { parseCheckinInstructionsAndExtractHighlightedItems } from './parseCheckinInstructionsAndExtractHighlightedItems'
import { salesforceApiAuthHeaders } from './authHeaders'
import { ListingObjectsType } from './types'

export type { ListingObjectsType }

const fetchListingsCheckinInstructionsSample = async (
  page: number,
  listingId: string | null,
) => {
  const url = listingId
    ? `${process.env.NEXT_PUBLIC_SALESFORCE_API_BASEURL}/listings/name/${listingId}`
    : `${process.env.NEXT_PUBLIC_SALESFORCE_API_BASEURL}/listings/sample/${page}`
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: salesforceApiAuthHeaders,
    })
    const data = await response.json()
    const listingObjects: ListingObjectsType = data.records?.length
      ? data.records
      : data
    if (listingObjects?.length == 0) {
      // todo: this probably needs to be an error of some sort.
      return null
    }
    for (let i: number = 0; i < listingObjects.length; i++) {
      const checkInInstructions = listingObjects[i].Check_in_Instructions__c
      if (checkInInstructions) {
        const {
          parsedInstructions,
          additionalInformation,
          propertyAddressExtras,
          guestContacts,
        } =
          parseCheckinInstructionsAndExtractHighlightedItems(
            checkInInstructions,
          )
        listingObjects[i].instructions = parsedInstructions
        listingObjects[i].additionalInformation = additionalInformation
        listingObjects[i].propertyAddressExtras = propertyAddressExtras
        listingObjects[i].guestContacts = guestContacts
      } else {
        listingObjects[i].instructions = null
        listingObjects[i].additionalInformation = null
        listingObjects[i].propertyAddressExtras = null
        listingObjects[i].guestContacts = null
      }
      listingObjects[i].Headline = listingObjects[i].Headline__c as string
      listingObjects[i].listingId = listingObjects[i].Name as string
      delete listingObjects[i].Name
      delete listingObjects[i].Check_in_Instructions__c
      delete listingObjects[i].Headline__c
      delete listingObjects[i].attributes
    }
    return listingObjects
  } catch (err) {
    return null
  }
}

export default fetchListingsCheckinInstructionsSample
