import { parse } from 'node-html-parser'
// this will take the content and split it into an array of instructions assuming all are separated by 2 newlines
function splitContentonDoubleNewlines(content: string) {
  return content.split(/\r?\n\r?\n/)
}

// split the items into title and body
function splitContentOnNewLines(content: string) {
  return content.split(/\r?\n/)
}

// this will take the specific instructions and split them into an array of lines assuming they are separated by a newline and a dash
function splitContentOnDashes(lines: string[]) {
  return lines.map((line: string) => line.replace(/^-\s?/, ''))
}

function cleanUp(instructions: string) {
  return instructions.replace('<br>', '').replace(/^\-/gi, '').trim()
}

// this will convert the first letter of each word and after dash to uppercase & strip the colon from the end if it exists
function convertToSentenceCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('-')
    .replace(/:$/, '')
}

/*
 * check if the initial instructions are HTML or plain text so we can parse them accordingly
 */
function isHtml(instructions) {
  const test = parse(instructions)
  return test.querySelector('p') !== null || test.querySelector('br') !== null
}

type ParsedPropertyInstruction = {
  title: string
  instructions: string[]
}[]

const parseHtmlCheckinInstructions = (instructions: string) => {
  const dom = parse(instructions)
  const plainText = dom.structuredText
  const parsedPropertyInstructions: ParsedPropertyInstruction = []
  let lines: string[] = []
  lines = plainText.split(/(?:\n\r?|<br>|\\n)/)
  let parsedItem: {
    title: string
    instructions: string[]
  } = {
    title: '',
    instructions: [],
  }
  lines.map((line) => {
    line = line.trim().replace(/\\n/g, '').replace(/\\r/g, '')
    // look for all uppercase lines with a colon at the end OR lines that do not start with a dash and end with a colon
    if (line.match(/(^[A-Z\-\s]+(:)?(\s+)?$|^(?!-)[A-Za-z\-\s]+:(\s+)?$)/)) {
      // if we have a title already, add the previous item to the array
      if (parsedItem.title !== '') {
        if (parsedItem.instructions.length > 0) {
          parsedPropertyInstructions.push(parsedItem)
        }
      }
      // create a new item
      parsedItem = {
        title: convertToSentenceCase(cleanUp(line)),
        instructions: [],
      }
    } else {
      // assume the line is an instruction
      parsedItem.instructions.push(cleanUp(line))
    }
  })
  // add the last item to the array
  if (parsedItem.instructions.length > 0) {
    parsedPropertyInstructions.push(parsedItem)
  }
  return parsedPropertyInstructions
}
/*
 * this function takes the checkin instructions as a string and parses them into an array of objects with the title and instructions
 *
 */
const parsePlainTextCheckinInstructions = (instructions: string) => {
  const parsedPropertyInstructions: ParsedPropertyInstruction = []
  const items = splitContentonDoubleNewlines(instructions)
  items.map((item) => {
    const lines = splitContentOnNewLines(item)
    const parsedItem: {
      title: string
      instructions: string[]
    } = {
      title: convertToSentenceCase(lines[0]),
      instructions: splitContentOnDashes(lines.slice(1)),
    }
    parsedPropertyInstructions.push(parsedItem)
  })
  return parsedPropertyInstructions
}

/*
 * this is the function that will be called from the PreStayInfomation component to actually parse the data and pull out the Additional Information & Property Addresds sections
 */
export const parseCheckinInstructionsAndExtractHighlightedItems = (
  instructions: string,
) => {
  // we need to test formatting of the additional information section, html or plain text
  let parsedInstructions: ParsedPropertyInstruction = []
  if (isHtml(instructions)) {
    parsedInstructions = parseHtmlCheckinInstructions(instructions)
  } else {
    parsedInstructions = parsePlainTextCheckinInstructions(instructions)
  }
  let additionalInformation: { title: string; instructions: string[] } | null =
    null
  let propertyAddressExtras: string[] | null = null
  let guestContacts: {
    count: number
    lines: string[]
  } | null = null
  if (parsedInstructions.length > 0) {
    const additionalInformationIndex = parsedInstructions.findIndex((element) =>
      element.title.match(/(Additional Information|Additional Info)/i),
    )
    if (additionalInformationIndex > -1) {
      additionalInformation = {
        title: 'Additional Information',
        instructions:
          parsedInstructions[additionalInformationIndex].instructions,
      }
      parsedInstructions.splice(additionalInformationIndex, 1)
    }
    const propertyAddressIndex = parsedInstructions.findIndex((element) =>
      element.title.match(/Property Address/i),
    )
    if (propertyAddressIndex > -1) {
      if (parsedInstructions[propertyAddressIndex].instructions.length > 1) {
        propertyAddressExtras =
          parsedInstructions[propertyAddressIndex].instructions.splice(1)
      }
      parsedInstructions.splice(propertyAddressIndex, 1)
    }
    const guestContactsIndex = parsedInstructions.findIndex((element) =>
      element.title.match(/Guest Contact/i),
    )
    if (guestContactsIndex > -1) {
      const guestContactsLines = parsedInstructions[
        guestContactsIndex
      ].instructions
        .filter(
          (instruction) =>
            !instruction.match(/^Please.+during your stay(\.)?$/i),
        )
        .map((instruction) => {
          if (
            instruction.match(
              /(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b|(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]{0,3}\d{3}[\s.-]{0,3}\d{4})/i,
            ) &&
            !instruction.match(/Please/i)
          ) {
            return instruction
              .replace(/(.+):([^,]+)/, '<b>$1:</b>$2')
              .replace(/\.+$/, '')
              .replace(/,\s*/gi, '<br>')
          } else {
            return instruction.replace(/\.+$/, '') + '.'
          }
        })
      const guestContactsCount = parsedInstructions[
        guestContactsIndex
      ].instructions.filter(
        (instruction) =>
          instruction.match(
            /(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b|(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]{0,3}\d{3}[\s.-]{0,3}\d{4})/i,
          ) && !instruction.match(/Please/i),
      ).length
      guestContacts = {
        count: guestContactsCount,
        lines: guestContactsLines,
      }
    }
  }
  return {
    parsedInstructions,
    additionalInformation,
    propertyAddressExtras,
    guestContacts,
  }
}
