import { parseCheckinInstructionsAndExtractHighlightedItems } from './parseCheckinInstructionsAndExtractHighlightedItems'
import { salesforceApiAuthHeaders } from './authHeaders'
import { BookingObjectType } from './types'

export type { BookingObjectType }

const fetchBookingWithListingByAuthCode = async (code: string) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_SALESFORCE_API_BASEURL}/bookings/code/${code}`,
      {
        method: 'GET',
        headers: salesforceApiAuthHeaders,
      },
    )
    const data = await response.json()
    if (data.totalSize === 0 || data.records?.length !== 1) {
      // todo: this probably needs to be an error of some sort.
      return null
    }
    const bookingObject = data.records[0]
    const listingObject = bookingObject.Listing__r
    const travelerObject = bookingObject.Traveler__r
    const checkInInstructions = listingObject.Check_in_Instructions__c
    if (checkInInstructions) {
      const {
        parsedInstructions,
        additionalInformation,
        propertyAddressExtras,
        guestContacts,
      } =
        parseCheckinInstructionsAndExtractHighlightedItems(checkInInstructions)
      listingObject.instructions = parsedInstructions
      listingObject.additionalInformation = additionalInformation
      listingObject.propertyAddressExtras = propertyAddressExtras
      listingObject.guestContacts = guestContacts
    } else {
      listingObject.instructions = null
      listingObject.additionalInformation = null
      listingObject.propertyAddressExtras = null
      listingObject.guestContacts = null
    }
    const bookingLineItems = bookingObject.Booking_Line_Items__r.records.filter(
      (item) => item.Quantity__c > 0,
    )
    if (bookingLineItems) {
      bookingObject.bookingLineItems = bookingLineItems.map((item) => {
        return {
          name: item.Name,
          description: item.Base_Description__c,
          totalAmount: item.Total_Amount__c,
          type: item.Type__c,
        }
      })
    } else {
      bookingObject.bookingLineItems = null
    }
    // ! RecordTypeId 012f20000001HW8AAM is the only way I could find to determine if a transaction record is a transfer.
    const transactions =
      bookingObject.Transactions_20180322221802658__r &&
      bookingObject.Transactions_20180322221802658__r.records &&
      bookingObject.Transactions_20180322221802658__r.records.length > 0
        ? bookingObject.Transactions_20180322221802658__r.records.filter(
            (item) => item.RecordTypeId !== '012f20000001HW8AAM',
          )
        : null
    if (transactions) {
      bookingObject.transactions = transactions.map((transaction) => {
        return {
          name: transaction.Name,
          dateProcessed: transaction.Date_Processed_MM_DD_YYYY__c,
          amount: transaction.bt_stripe__Amount__c,
          dateDue: transaction.bt_stripe__Due_Date__c,
          type: transaction.bt_stripe__Transaction_Type__c,
          status: transaction.bt_stripe__Transaction_Status__c,
        }
      })
    } else {
      bookingObject.transactions = null
    }
    bookingObject.totalAmount = bookingLineItems
      ? bookingLineItems.reduce((acc, item) => {
          return acc + item.Total_Amount__c
        }, 0)
      : bookingObject.Total_Amount__c - bookingObject.Credits_Applied__c
    bookingObject.amountPaid = bookingObject.transactions
      ? bookingObject.transactions.reduce((acc, item) => {
          if (item.dateProcessed && item.status === 'Completed') {
            return acc + item.amount
          }
          return acc
        }, 0)
      : bookingObject.Amount_Paid__c
    if (!bookingObject.amountPaid) {
      bookingObject.amountPaid = ''
    }
    bookingObject.taxTotal = bookingObject.Tax_Total__c
    bookingObject.finalPaymentDue =
      bookingLineItems && transactions
        ? bookingObject.totalAmount - bookingObject.amountPaid
        : bookingObject.Open_Charges__c
    if (!bookingObject.finalPaymentDue) {
      bookingObject.finalPaymentDue = ''
    }
    bookingObject.totalNights = bookingObject.Total_Nights__c
    bookingObject.totalTravelInsurance = bookingObject.Total_Travel_Insurance__c
    bookingObject.feeTotal = bookingObject.Fee_Total__c
    bookingObject.creditsApplied = bookingObject.Credits_Applied__c
    bookingObject.bookingStatus = bookingObject.Status__c
    listingObject.testVariant = listingObject.Test_Variant__c
    listingObject.latitude = listingObject.Latitude__c
    listingObject.longitude = listingObject.Longitude__c
    delete bookingObject.Transactions_20180322221802658__r
    delete bookingObject.Booking_Line_Items__r
    delete bookingObject.Listing__r
    delete bookingObject.Traveler__r
    delete bookingObject.attributes
    delete bookingObject.Total_Nights__c
    delete bookingObject.Status__c
    delete listingObject.attributes
    delete listingObject.Check_in_Instructions__c
    delete listingObject.Test_Variant__c
    delete listingObject.Latitude__c
    delete listingObject.Longitude__c
    delete travelerObject.attributes
    return { bookingObject, listingObject, travelerObject }
  } catch (err) {
    return null
  }
}

export default fetchBookingWithListingByAuthCode
