import { salesforceApiAuthHeaders } from './authHeaders'

const fetchFieldsByAuthCode = async (code: string, fields: string[]) => {
  try {
    const fieldsString = fields.join(',')
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_SALESFORCE_API_BASEURL}/bookings/code/${code}/${fieldsString}`,
      {
        method: 'GET',
        headers: salesforceApiAuthHeaders,
      },
    )
    const data = await response.json()
    if (data.totalSize === 0 || data.records?.length !== 1) {
      // todo: this probably needs to be an error of some sort.
      return null
    }
    const booking = data.records[0]
    const listing = booking.Listing__r ? booking.Listing__r : null
    const traveler = booking.Traveler__r ? booking.Traveler__r : null

    delete booking.attributes
    delete booking.Listing__r
    delete booking.Traveler__r
    if (listing) {
      delete listing.attributes
    }
    if (traveler) {
      delete traveler.attributes
    }
    return { booking, listing, traveler }
  } catch (err) {
    return null
  }
}

export default fetchFieldsByAuthCode
