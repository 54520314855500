import { parseCheckinInstructionsAndExtractHighlightedItems } from './parseCheckinInstructionsAndExtractHighlightedItems'
import { salesforceApiAuthHeaders } from './authHeaders'
import { ListingObjectsType } from './types'

export type { ListingObjectsType }

const fetchListingByPublicId = async (id: string) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_SALESFORCE_API_BASEURL}/listings/name/${id}`,
      {
        method: 'GET',
        headers: salesforceApiAuthHeaders,
      },
    )
    const data = await response.json()
    const objects: ListingObjectsType = data.records?.length
      ? data.records
      : [data]
    const listingObject = objects[0]
    const checkInInstructions = listingObject.Check_in_Instructions__c
    if (checkInInstructions) {
      const parsedContent =
        parseCheckinInstructionsAndExtractHighlightedItems(checkInInstructions)
      listingObject.instructions = parsedContent.parsedInstructions
      listingObject.additionalInformation = parsedContent.additionalInformation
    } else {
      listingObject.instructions = null
      listingObject.additionalInformation = null
    }
    return listingObject
  } catch (err) {
    return null
  }
}

export default fetchListingByPublicId
