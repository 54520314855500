export const salesforceApiAuth = {
  client_id: process.env.NEXT_PUBLIC_SALESFORCE_API_CLIENT_ID!,
  client_secret: process.env.NEXT_PUBLIC_SALESFORCE_API_CLIENT_SECRET!,
}

export const salesforceApiAuthHeaders = process.browser
  ? new Headers({
      Authorization:
        'Basic ' +
        btoa(
          salesforceApiAuth.client_id + ':' + salesforceApiAuth.client_secret,
        ),
    })
  : new Headers({
      Authorization:
        'Basic ' +
        Buffer.from(
          salesforceApiAuth.client_id + ':' + salesforceApiAuth.client_secret,
        ).toString('base64'),
    })
